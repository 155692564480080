import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import Seo from '../components/Seo';

export default function PageTemplate({
  data,
}) {
  const { markdownRemark } = data;
  const { fields, frontmatter, html } = markdownRemark;
  const description = html || '';
  const image = frontmatter.featured_image ? frontmatter.featured_image.publicURL : null;
  return (
    <section className="bg-white mx-auto py-12 lg:pb-20 px-4 sm:px-6 lg:px-8 max-w-lg sm:max-w-2xl md:max-w-4xl lg:max-w-7xl">
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-6 xl:gap-x-12">
        {/* eslint-disable max-len */}
        <header className="col-span-2 pb-4" data-aos="fade-in" data-aos-once="true" data-aos-delay="0">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">{frontmatter.title}</h1>
        </header>
        <div className="col-span-1 pb-4 sm:max-w-lg mx-auto">
          {frontmatter.featured_image
            && (
              <GatsbyImage
                image={frontmatter.featured_image.childImageSharp.gatsbyImageData}
                role="presentation"
                loading="eager"
                alt=""
                objectFit="fill"
                className="border-6 border-gray-50"
              />
            )}
        </div>
        <div className="col-span-1 pb-4 sm:max-w-lg mx-auto">
          {html
            && (
              <div
                id="block-page"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
        </div>
      </div>
      <Seo
        slug={fields.slug}
        title={frontmatter.title}
        description={description}
        image={image}
      />
    </section>
  );
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        featured_image: PropTypes.shape({
          publicURL: PropTypes.string,
          childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.shape({}),
          }),
        }),
      }).isRequired,
      html: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

PageTemplate.defaultProps = {};

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featured_image {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 650,
            )
          }
        }
      }
    }
  }
`;
